<template>
  <div class="iotProduct">
    <v-list
      ref="list"
      :isAdd="true"
      :isOperateColumn="false"
      :headers="headers"
      :tableUrl="tableUrl"
      :searchParam.sync="searchParam"
    >
      <template #headBtnSlot> </template>
      <template #searchSlot>
        <v-autocomplete
          label="产品名称"
          v-model="searchParam.productName"
          @change="change"
          :querySearchAsync="querySearchAsync"
        ></v-autocomplete>
        <v-input label="设备序列号" v-model="searchParam.deviceSN" />
      </template>
      <!-- <template #operateSlot="scope">
        <v-button text="编辑" type="text" @click="toEdit(scope.row)" />
        <v-button text="删除" type="text" @click="toDelete(scope.row)" />
      </template> -->
    </v-list>
  </div>
</template>

<script>
import { getIotWarnList, getIotProductList } from "./api.js";
import {} from "./map.js";
import {
  createAlinkVNode,
  createImgVNode,
  createHTMLVNode,
} from "@/utils/utils.js";

export default {
  name: "realNameAuditList",
  data() {
    return {
      productList: [],
      searchParam: {
        deviceSN: "",
        productName: "",
        productId: "",
      },

      tableUrl: getIotWarnList,
      headers: [
        {
          prop: "productName",
          label: "产品名称",
        },
        {
          prop: "deviceName",
          label: "设备名称",
        },
        {
          prop: "deviceSN",
          label: "设备序列号",
        },
        {
          prop: "content",
          label: "告警内容",
        },
        {
          prop: "statusName",
          label: "告警状态",
        },
        {
          prop: "levelName",
          label: "告警类型",
        },
        {
          prop: "reportDate",
          label: "创建时间",
        },
        {
          prop: "orderId",
          label: "工单编号",
          formatter: (row, prop) => {
            let oBtn = `<span>${row.orderId}</span> `;
            return createHTMLVNode(this, oBtn, row, prop, {
              cb: (e, row) => {
                this.$router.push({
                  name: "workOrder",
                });
              },
            });
          },
        },

        // {
        //   prop: "avatarUrl",
        //   label: "图片",
        //   formatter: (row, prop) => createImgVNode(this, row, prop),
        // },
        // {
        //   prop: "isPartyMember",
        //   label: "是否党员",
        //   formatter: (row, prop) => {
        //     return isPartyStatusMap[row.isPartyMember];
        //   },
        // },
      ],
    };
  },
  computed: {},
  methods: {
    change(value) {
      this.searchParam.productId = value.id;
    },
    async querySearchAsync(queryString, cb) {
      console.log(queryString, cb);
      let results = await this.getProList(this.searchParam.productName);
      cb(results);
    },
    async getProList(productName) {
      let params = { name: productName, curPage: 1, pageSize: 1000 };
      this.productList = [];
      let res = await this.$axios.get(`${getIotProductList}`, { params });
      if (res.code === 200) {
        res.data.records.forEach((item) => {
          item.label = item.id;
          item.value = item.name;
          this.productList.push(item);
        });
        return this.productList;
      }
    },
    toDelete(item) {
      // this.$confirm("是否确定删除?", "提示", {
      //   confirmButtonText: "确定",
      //   cancelButtonText: "取消",
      //   type: "warning",
      // }).then(() => {
      //   let params = {
      //     deviceId: item.id,
      //   };
      //   this.$axios.post(`${deleteProUrl(params)}`).then((res) => {
      //     if (res.code === 200) {
      //       this.$message.success("操作成功");
      //       this.$refs.list.search();
      //     } else {
      //     }
      //   });
      // });
    },

    toAdd() {
      // this.$router.push({
      //   name: "iotProductForm",
      // });
    },
    toEdit(row) {
      // this.$router.push({
      //   name: "iotProductForm",
      //   query: {
      //     id: row.id,
      //   },
      // });
    },
  },
};
</script>
<style lang="less" scoped>
.iotProduct {
  box-sizing: border-box;
  height: 100%;
}
</style>
